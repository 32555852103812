import type { ButtonProps as MuiButtonProps, PropTypes } from '@material-ui/core';
import { Button as MuiButton, createMuiTheme, MuiThemeProvider, useTheme } from '@material-ui/core';
import type { FC } from 'react';
import { forwardRef } from 'react';
import * as Colors from '../theme/colors';
import CircularProgress from './CircularProgress';

interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  success?: boolean;
  error?: boolean;
  info?: boolean;
  warning?: boolean;
}

const createTheme = (main: string, contrastText?: string) =>
  createMuiTheme({
    palette: { primary: { main, ...(contrastText ? { contrastText } : {}) } },
  });

const themes = {
  success: createTheme(Colors.success, Colors.white),
  error: createTheme(Colors.error),
  info: createTheme(Colors.info),
  warning: createTheme(Colors.warning, Colors.white),
};

const Button: FC<ButtonProps> = forwardRef(
  (
    {
      loading = false,
      variant = 'contained',
      success = false,
      error = false,
      info = false,
      warning = false,
      color,
      ...rest
    },
    ref,
  ) => {
    const defaultTheme = useTheme();
    let customTheme;
    if (success) customTheme = themes.success;
    else if (error) customTheme = themes.error;
    else if (info) customTheme = themes.info;
    else if (warning) customTheme = themes.warning;

    const defaultProps = {
      variant,
      // eslint-disable-next-line no-nested-ternary
      ...(customTheme ? { color: 'primary' as PropTypes.Color } : color ? { color } : {}),
    };

    return (
      <MuiThemeProvider theme={customTheme || defaultTheme}>
        <MuiButton
          {...defaultProps}
          {...rest}
          ref={ref}
          disabled={loading || rest.disabled}
          startIcon={loading ? <CircularProgress size={16} color="secondary" /> : rest.startIcon}
        />
      </MuiThemeProvider>
    );
  },
);
Button.displayName = 'Button';

export default Button;
