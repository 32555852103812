import NotFoundPage from '@/pages/404';
import ErrorPage from '@/pages/error';
import { SentryService } from '@/services/sentry';
import type { FC } from 'react';
import { useRouteError } from 'react-router';

const ErrorBoundary: FC = () => {
  const error: any = useRouteError();
  if (error && error.status === 404 && error.internal) {
    return <NotFoundPage />;
  }

  SentryService.trackError(error);
  return <ErrorPage />;
};

export default ErrorBoundary;
