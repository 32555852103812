export const blue = '#0F6EFF';
export const green = '#38C83E';
export const orange = '#FFA41E';
export const red = '#FA4F43';
export const white = '#FFFFFF';
export const black = '#000000';
export const darkGrey = '#102027';
export const darkGrey10 = '#E7E8E9';
export const darkGrey16 = '#D9DBDC';
export const darkGrey40 = '#9FA6A9';
export const darkGrey62 = '#6B7579';
export const darkGrey86 = '#324046';
export const lightGrey = '#F5F5F5';

/** @alias */
export const info = blue;
/** @alias */
export const success = green;
/** @alias */
export const warning = orange;
/** @alias */
export const error = red;
/** @alias */
export const primary = blue;
/** @alias */
export const secondary = darkGrey;
/** @alias */
export const disabled = darkGrey40;
/** @alias */
export const hover = lightGrey;
